<template lang="pug">
.page.answer
  .group 题目解析
  .content
    div(v-for='(currentPaidQuestion, index) in questionsData', :key='index')
      .save_progress(@click='gohome') 返回首页
      .title {{ index + 1 }}、{{ currentPaidQuestion && currentPaidQuestion.content }} - {{ currentPaidQuestion && currentPaidQuestion.groupTitle }}
      .as_list(v-if='currentPaidQuestion && currentPaidQuestion.answerList && currentPaidQuestion.answerList.length')
        .as(v-for='(answer, i) in currentPaidQuestion.answerList') {{ answer.tag }}、{{ answer.answer }}
          img.icon.jian(src='../assets/img/as_jian.png', v-if="answer.isRight")
          img.icon.wo(src='../assets/img/as_wo.png', v-if="answer.select")

      //- .my_as
        .t 我的选择 {{ currentPaidQuestion.slectAnswers }}
        .span
          span(v-for='(answer, i) in currentPaidQuestion.answerList', v-if='answer.select') {{ answer.tag }}

      .explanation_title(v-if='currentPaidQuestion && currentPaidQuestion.explanation') 解析
      .explanation(v-if='currentPaidQuestion && currentPaidQuestion.explanation') {{ currentPaidQuestion.explanation }}
      //- .line_btn(@click='goGroup') 加群吐槽 →

    .btns
      .btn(@click='prev') 返回上一页
      .btn.blue(@click='next') 查看结果
</template>

<script>
import { getQueryString, scrollToTop } from '../assets/js/utils'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default {
  name: 'Question',
  data() {
    return {
      currentIdx: 0,
      myAnswers: []
    }
  },
  computed: {
    questionsData() {
      return this.$store.state.paidQuestionsData
    }
  },
  methods: {
    goGroup() {
      window.location.href = 'https://marketing.kexueshengyin.com/#/wechatgroup/6'
    },
    gohome() {
      this.$router.push('/')
    },
    async next() {
      this.$router.push('/result')
      scrollToTop()
    },
    prev() {
      this.$router.go(-1)
    },
    toWeixin() {
      const url = process.env.VUE_APP_API_URL
      window.location.href = `${url}/login/wechat/web/redirect?state=${encodeURIComponent(window.location.href)}&scope=snsapi_base&redirectURI=${encodeURIComponent(window.location.href)}`
    },
    async getSelfAnswer() {
      if (!this.questionsData || !this.questionsData.length) {
        setTimeout(() => {
          this.getSelfAnswer()
        }, 200)
        return
      }
      const data = await this.$api.getQuizzesResults(this.$store.state.currentQuizze.id)
      for (let o of data) {
        for (let q of this.questionsData) {
          if (o.questionID == q.id) {
            // o.answerList
            let arr = []
            for (let sa of o.answerList) {
              for (let qa of q.answerList) {
                if (qa.id === sa) {
                  qa.select = true
                  break
                }
              }
            }
            break
          }
        }
      }

      this.$store.commit('SET_PAID_QUESTIONS_DATA', this.questionsData)
    }
  },
  async created() {
    this.$loading.show()
    await this.$store.dispatch('getQuestionsPaid')
    if (this.$store.state.currentQuizze && this.$store.state.currentQuizze.id) {
      this.getSelfAnswer()
    }
    this.$loading.hide()

    const ready = () => {
      this.$regWeChat()
      const code = getQueryString('code')
      if ((!this.$store.state.userInfo.openID || !this.$store.state.userToken.accessToken) && !code) {
        window.location.href.indexOf('/login') < 0 && this.$setExpireStore('fromPath', window.location.href)
        const url = process.env.VUE_APP_API_URL
        window.location.replace(`${url}/login/wechat/web/redirect?state=${encodeURIComponent(window.location.href)}&scope=snsapi_userinfo&redirectURI=${encodeURIComponent(window.location.href)}`)
      }
    }

    if (this.$store.state.isWeixin && (!window.WeixinJSBridge || !WeixinJSBridge.invoke)) {
      document.addEventListener('WeixinJSBridgeReady', ready, false)
    } else {
      ready()
    }
  }
}
</script>

<style lang="stylus" scope>
.answer
  background: #0563DA
  background-size: cover
  padding-top: 1rem

  .line_btn
    font-size: 0.24rem
    color: lighten(#0563DA, 30%)
    text-align: center
    padding: .1rem 0.3rem
    border-radius .5rem
    // border 1px solid lighten(#0563DA, 30%)
    display: block
    width: 1.8rem
    margin: .3rem auto 0
    text-decoration: underline

  .group
    opacity: 0.42
    font-size: 0.7rem
    line-height: 1
    color: #FFFFFF
    position: relative
    top: 0.05rem
    text-align: right
    padding-right: 0.3rem

  .content
    background: #fff
    padding: 0.6rem 0.32rem 0.44rem
    padding-bottom: calc(constant(safe-area-inset-bottom) / 2 + 0.6rem)
    padding-bottom: calc(env(safe-area-inset-bottom) / 2 + 0.6rem)
    margin: 0 0.16rem
    border-radius: 0.4rem 0.4rem 0 0
    transition: all 0.3s ease
    position: relative
    box-sizing: border-box
    min-height: calc(100vh - 1.7rem)

    .save_progress
      font-size: 0.24rem
      color: #0563DA
      text-align: center
      display: flex
      align-items: center
      justify-content: center
      position: absolute
      right: 0.32rem
      top: 0.55rem
      background: #FFFFFF
      border: 1px solid #0563DA
      border-radius: 0.36rem
      padding: 0.05rem 0.2rem

    .idx
      color: #333333
      font-size: 0.36rem
      line-height: 1

      &.pt
        padding-top: 0.6rem

      &:not(:first-child)
        margin-top: 0.5rem

      span
        font-size: 0.32rem

    .title
      font-size: 0.36rem
      color: #333333
      font-weight: bold
      line-height: 1.6
      padding-top: 0.6rem

    .explanation_title
      margin-top: 0.3rem
      font-size: 0.28rem
      color: #333333
      font-weight: bold
      line-height: 1
      padding-bottom: 0.3rem
      text-align: left
      margin-left: 0.2rem

    .explanation
      padding: 0 0.2rem
      white-space: pre-wrap
      color: #0563DA
      line-height: 1.5

    .as_list
      margin-top: 0.4rem

      .as
        background: #F5F5F5
        border-radius: 0.64rem
        font-size: 0.3rem
        color: #221E1E
        line-height: 1.7
        margin-top: 0.28rem
        padding: 0.3rem 0.6rem
        position: relative

        &.active
          color: #fff
          background: #0B73F7
          box-shadow: 0 0.04rem 0.14rem 0 rgba(11, 115, 247, 0.43)
          border-radius: 0.64rem

        .icon
          width: 0.7rem
          height: 0.7rem
          position: absolute
          top: -0.2rem

          &.jian
            left: -0.1rem

          &.wo
            right: -0.1rem

    .my_as
      margin-top: 0.6rem
      font-size: 0.28rem
      color: #333333
      font-weight: bold
      line-height: 1
      padding-bottom: 0.3rem
      text-align: left
      margin-left: 0.2rem

      .span
        margin-top: 0.3rem

        span
          margin-right: 0.2rem
          line-height: 1
          font-size: 0.28rem
          color: #0563DA

  .btns
    width: 100%
    margin: 0 auto
    display: flex
    flex-flow: row no-wrap
    align-items: center
    justify-content: space-between
    margin-top: 0.4rem

    .btn
      background: #FFFFFF
      border: 1px solid #979797
      border-radius: 0.5rem
      height: 1rem
      width: 3.05rem
      font-size: 0.36rem
      color: #999999
      display: flex
      flex-flow: row no-wrap
      align-items: center
      justify-content: center

      &.blue
        color: #fff
        border-color: #0563DA
        background: #0563DA
</style>
